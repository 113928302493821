/**
 * Get info about document records to use in Table
 *
 * @param {Array} records
 *  Records of a document
 *
 * @returns {Object}
 * 	Obejct with info about given flow
 */
export const formatForTable = (records, keyField, firstField, secondField) => {
	const result = [];
	// loop over fields
	if (records) {
		records.forEach((record, key) => {
			const obj = {
				key, // key, useful for handling clicks
				key_field: record[keyField],
				id: record[keyField],
				edit: true,
			};
			if (firstField) {
				obj.first_field = record[firstField];
			}
			if (secondField) {
				obj.second_field = record[secondField];
			}
			result.push(obj);
		});
	}

	return result;
};
