<template>
    <VOverlay
        :hasBackground="false"
        :hasJsonButton="false"
        :hasCloseButton="true"
        :closeOnBackgroundClick="true"
        :isLarge="true"
        :isVCentered="false"
        :hasFullwidthBody="true"
        :title="title"
        :modelValue="true"
        @update:modelValue="overlayClosed"
        :useFocusTrap="false"
        :modalHasSharpCorner="true"
        @keydown.meta.s.prevent.stop="saveRecord"
    >
        <VField
            :class="{ 'has-errors': showErrors }"
            class="has-margin-right-1 is-wide"
        >
            <json-forms
                :data="data"
                :renderers="renderers"
                :schema="jsonformsmethods.generateSchema(document.fields)"
                :uischema="jsonformsmethods.generateUiSchema(document.fields, data, hasErrors, saved)"
                validationMode="ValidateAndShow"
                @input="onInput"
                @change="onChange"
                :readonly="!canEditRecord"
            />
        </VField>

        <template #footer>
            <VButton
                v-if="canEditRecord"
                :disabled="!modified"
                class="button-modal-footer"
                :text="$t('general.save')"
                @clicked.prevent.stop="saveRecord"
            />
        </template>
    </VOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    getStoreGetter,
    getStoreMutation,
    getStoreAction,
} from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import { JsonForms } from '@jsonforms/vue';
import {
    defaultStyles,
    mergeStyles,
    vanillaRenderers,
} from '@jsonforms/vue-vanilla';
import jsonformsmethods from '@assets/scripts/components/jsonformsmethods';

const renderers = [
    ...vanillaRenderers,
    // here you can add custom renderers
];

// mergeStyles combines all classes from both styles definitions into one
const myStyles = mergeStyles(defaultStyles, {
    control: { label: 'label-floating' },
});

export default {
    name: 'TheRecordDetails',
    components: {
        JsonForms,
    },
    data: function () {
        return {
            showErrors: false,
            hasErrors: false,
            renderers: Object.freeze(renderers),
			data: {},
			saved: false,
			title: '',
        };
    },
    setup: function () {
		return {
			jsonformsmethods,
		};
	},
	mounted: function() {
		this.data = this.record || {};
		if(Object.keys(this.data).length !== 0) {
			this.saved = true;
		}

		let title =  this.$t('de.recDetails.title', {name: this.document.name});
		if(Object.keys(this.data).length === 0) {
			title = this.$t('de.recDetails.createNew', {fullName: title});
		}
		this.title = title
	},
    computed: {
        ...mapGetters({
            /**
             * Boolean to indicate whether user modified the document
             */
            modified: getStoreGetter('MODIFIED', 'DE'),
            /**
             * Gets currently active Document
             */
            document: getStoreGetter('CURRENT_DOCUMENT', 'DE'),
			/**
             * Gets currently active Record
             */
			record: getStoreGetter('CURRENT_RECORD', 'DE'),
        }),
        /**
         * Boolean to indicate whether the current
         * user can edit records
         */
        canEditRecord: function () {
            return usePermission('Upsert', 'DocumentEditor');
        },
    },
    methods: {
        markRecAsUpdated: function () {
            // no need to trigger this mutation more than once
            if (this.modified) return;

            // mark record as modified whenever a
            // change is made
            this.$store.commit(getStoreMutation('MARK_MODIFIED', 'DE'));
        },
        overlayClosed: function () {
            const closeOverlay = () => {
                // dispatch action to unset document
                this.$store.commit(getStoreAction('RESET', 'DE'));
            };

            if (!this.modified) {
                // close immediately if config has not been modified
                closeOverlay();
            } else {
                // ask confirmation before closing if a change has been made
                // to the Document
                this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
                    title: this.$t('de.recDetails.close.confirm.title'),
                    body: this.$t('de.recDetails.close.confirm.body'),
                    confirmButtonText: this.$t(
                        'de.recDetails.close.confirm.confirmButtonText'
                    ),
                    confirmFn: () => {
                        // close after confirmation
                        closeOverlay();
                    },
                });
            }
        },
        saveRecord: function () {
            if (this.hasErrors) {
                this.showErrors = true;
            } else {
                this.$store.dispatch(getStoreAction('SAVE_CURRENT_RECORD', 'DE'), this.data );
				this.saved = true
            }
        },
        onChange(event) {
            this.data = event.data;
            this.hasErrors = event.errors.length > 0;
        },
        onInput() {
            this.markRecAsUpdated();
        },
    },
    provide() {
        return {
            styles: myStyles,
        };
    },
};
</script>
