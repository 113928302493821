<template>
	<div class="app-layer"></div>
	<div class="overlay-layer app-layer is-active">
		<TheRecordDetails v-if="showDetails" />
		<Suspense>
			<TheRecordOverview
				v-if="overviewAvailable"
				v-show="showOverview"
			/>
		</Suspense>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import TheRecordDetails from '@modules/DocumentEditor/materials/structures/TheRecordDetails.vue';
import TheRecordOverview from '@modules/DocumentEditor/materials/TheRecordOverview.vue';

export default {
	name: 'TheDocumentEditor',
	components: {
		TheRecordDetails,
		TheRecordOverview,
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether a document is
			 * currently active
			 */
			documentActive: getStoreGetter('DOCUMENT_ACTIVE', 'DE'),
		}),
		/**
		 * Boolean to indicate whether the current
		 * user can read (view) records
		 */
		canReadRecord: function () {
			return usePermission('Read', 'DocumentEditor');
		},
		showDetails: function () {
			return this.documentActive && this.canReadRecord;
		},
		overviewAvailable: function () {
			return this.canReadRecord;
		},
		showOverview: function () {
			return !this.showDetails && this.overviewAvailable;
		},
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'DE'));
	},
};
</script>
