<template>
	<div class="content-header has-bottom-divider has-padding-top-3 has-margin">
		<div class="field is-grouped is-flex-grow-1">
			<VField
				:label="$t('de.recList.recTypes')"
				:isFloating="true"
				class="has-margin-right-1"
				:class="{ 'is-active': recTypeValue }"
			>
				<VSelect
					:options="recTypeOptions"
					v-model="recTypeValue"
					:onChange="getDocumentRecordsData"
				/>
			</VField>
			<VLink
				v-if="canEditRecord"
				:text="$t('de.recList.addRecord')"
				icon="plus"
				:hasUnderline="true"
				class="has-margin-right-auto has-margin-left-0"
				@click.prevent.stop="addRecord"
				:isDisabled="!this.recTypeValue"
			/>

			<VSearch v-model="search" />
		</div>
	</div>

	<div class="scrollable-content">
		<SortableDataTable
			:scrollable="false"
			:rowClickedFn="rowClicked"
			defaultSort="last_time_edited"
			sortDirection="DESC"
			:data="filteredData"
			:columns="columns"
			:emptyText="$t('de.recList.table.noResults')"
		/>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { reactive, toRefs } from '@vue/reactivity';
import { getStoreAction, getStoreMutation } from '@assets/scripts/store/config';
import { formatForTable } from '@modules/DocumentEditor/components/connection-record';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { useCreateNewOnKeydown } from '@assets/scripts/composables/useCreateNewOnKeydown';
import usePermission from '@assets/scripts/composables/usePermission';
import {
	GET_RECORDS,
} from '@modules/DocumentEditor/endpoints';

import {
	GET_FUNCTION_LISTS_BY_CONNECTION,
} from '@assets/scripts/api/config';

export default {
	name: 'RecordList',
	props: {
		connGuid: {
			type: String,
			required: true,
		},
	},
	async setup (props) {
		const store = useStore();

		const state = reactive({
			records: [],
			recTypeValue: false,
			functionLists: [],
			recTypeOptions: [],
			/**
			 * Boolean to indicate whether the current
			 * user can create records
			 */
			canEditRecord: usePermission('Upsert', 'DocumentEditor'),
		});

		const addRecord = () => {
			store.dispatch(getStoreAction('CREATE_NEW_RECORD', 'DE'));
			// commit mutation to set current document
			setDocument();
		}

		const setDocument = () => {
			const List = state.functionLists.find((el) => el.guid === state.recTypeValue)

			store.commit(
				getStoreMutation('SET_CURRENT_DOCUMENT', 'DE'),
				List,
				{ root: true }
			);
		}	

		if(state.canEditRecord) {
			useCreateNewOnKeydown(addRecord);
		}

		const loadRecords = async (conn_guid, document_guid) => {
			// get all Connection Document records
			return await useApiAsync(GET_RECORDS, {
				keys: {
					connection: conn_guid,
					guid: document_guid
				}
			});
		};

		// subscribe to store action
		const unsubscribeAction = store.subscribeAction({
			after: async ({ type, payload }) => {
				// check action type and relevant connection GUID
				if (
					type === getStoreAction('UPDATE_RECORD_LIST', 'DE') &&
					payload.conn_guid === props.connGuid &&
					payload.document_guid === state.recTypeValue
				) {
					// reload record list if needed
					state.records = await loadRecords(
						props.connGuid,
						state.recTypeValue
					);
				}
			},
		});

		// get all available function lists
		state.functionLists = await useApiAsync(GET_FUNCTION_LISTS_BY_CONNECTION, {
			keys: {
				connection: props.connGuid,
			}
		});

		// prepare function lists for select field
		state.recTypeOptions = Helpers.mapObjectArrayForSelect(state.functionLists, {
			key: 'guid',
			text: 'name',
		});

		return { ...toRefs(state), unsubscribeAction, loadRecords, addRecord, setDocument};
	},
	data: function () {
		return {
			// default value for Record Type filter
			search: '',
			documentGuid: '',
			keyField: false,
			firstField: false,
			secondField: false,
		};
	},
	computed: {
		columns: function () {
			const columnsArray = [];
			if (this.keyField) {
				columnsArray.push({
					label: this.keyField.name,
					field: 'key_field',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				});
			}
			if (this.firstField) {
				columnsArray.push({
					label: this.firstField.name,
					field: 'first_field',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				});
			}
			if (this.secondField) {
				columnsArray.push({
					label: this.secondField.name,
					field: 'second_field',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
					// width: '30%',
				});
			}
			columnsArray.push({
				label: '',
				field: 'edit',
				sortable: false,
				default: '',
				component: 'VButton',
				cellClass: 'is-button-tool',
				args: {
					href: '',
						title: this.canEditRecord
							? this.$t('general.edit')
							: this.$t('general.inspect'),
					isTool: true,
					class: '',
					icon: this.canEditRecord ? 'edit' : 'eye',
				},
				click: this.rowClicked,
			});
			return columnsArray;
		},
		tableFields: function () {
			const retval = [this.records, this.keyField.name];
			if (this.firstField) {
				retval.push(this.firstField.name);
			}
			if (this.secondField) {
				retval.push(this.secondField.name);
			}
			return formatForTable(...retval);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// loop over all records
			const data = this.tableFields;

			// return if no rows match
			if (this.search.length === 0 || data.length === 0) return data;

			// filter on search string
			return Helpers.filterByString(
				data,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		async rowClicked({ id }) {
			// dispatch action to show clicked record
			await this.$store.dispatch(
				getStoreAction('LOAD_AND_SHOW_RECORD', 'DE'),
				{
					conn_guid: this.connGuid,
					document_guid: this.recTypeValue,
					keyField: this.keyField.name,
					keyFieldValue: id,
				}
			);
			// commit mutation to set current document
			this.setDocument();
		},
		sortByDate(a, b, isAsc) {
			return Helpers.date.sortByIsoDate(a.time, b.time, isAsc);
		},
		async getDocumentRecordsData() {
			// unset firsField, secondField and keyField
			this.firstField = false;
			this.secondField = false;
			this.keyField = false;

			// update firstFiled and secondField this update also columns computed value
			this.functionLists.forEach((list) => {
				list.fields.forEach((obj) => {
					if (obj.is_key && list.guid == this.recTypeValue) {
						this.keyField = obj;
					}
					if (
						!obj.is_key &&
						list.guid == this.recTypeValue &&
						Object.keys(this.firstField).length === 0
					) {
						this.firstField = obj;
					}
					if (
						!obj.is_key &&
						obj != this.firstField &&
						list.guid == this.recTypeValue &&
						Object.keys(this.secondField).length === 0
					) {
						this.secondField = obj;
					}
				});
			});

			// then get all Connection Document Record
			this.records = await this.loadRecords(
				this.connGuid,
				this.recTypeValue
			);
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
