<template>
	<VOverlay
		:hasBackground="false"
		:hasJsonButton="false"
		:hasCloseButton="false"
		:closeOnBackgroundClick="false"
		:isLarge="true"
		:isVCentered="false"
		:hasFullwidthBody="true"
		:hasScrollableContent="true"
		v-model="overlayIsActive"
		:title="$t('de.documentList.title')"
		:useFocusTrap="false"
		:closeWithEscape="false"
	>
		<VTabs v-if="connections">
			<VTabItem
				v-for="(item, index) in connections"
				:key="index"
				:title="item.name"
			>
				<Suspense>
					<RecordList
						:connGuid="item.conn_guid"
					/>
				</Suspense>
			</VTabItem>
		</VTabs>
	</VOverlay>
</template>

<script>
import RecordList from '@modules/DocumentEditor/materials/structures/RecordList.vue';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_CONNECTIONS } from '@assets/scripts/api/config';

export default {
	name: 'TheRecordOverview',
	components: {
		RecordList,
	},
	data: function () {
		return {
			overlayIsActive: true,
		};
	},
	async setup() {
		// get all Connections
		const connections = await useApiAsync(GET_CONNECTIONS);
		return { connections };
	},
};
</script>
